<template>
  <section class="main-bg m-mh">
    <navi-bar>
      쿠폰
    </navi-bar>

    <div class="moneytab">
      <a :class="{'on' : type == 'coupon'}" @click="type = 'coupon'"><img src="@/assets_mobile/img/coupon.png" /><span>쿠폰사용</span></a>
      <a :class="{'on' : type == 'gift'}" @click="type = 'gift'"><img src="@/assets_mobile/img/coupon.png" /><span>쿠폰선물</span></a>
      <a :class="{'on' : type == 'list' || type == 'giftlist'}" @click="type = 'list'"><img src="@/assets_mobile/img/coupon.png" /><span>쿠폰내역</span></a>
    </div>

    <template v-if="type == 'coupon'">
      <div class="pointinwrap">
        <p class="pointname">사용 가능한 쿠폰<strong class="rd">10</strong>장</p>
      </div>

      <div class="gamebetwrap">
        <div class="gamebetlist">
          <ul class="gamebetdetail w100-50">
            <li><span>쿠폰명</span>:테스트쿠폰</li>
            <li><span>쿠폰금액</span>:<span class="rd">5,000</span></li>
            <li><span>보낸회원아이디</span>:eric123</li>
            <li><span>처리상태</span>:<span class="rd">사용가능</span></li>
            <li><span>발급일</span>:2021-06-29</li>
            <li><span>만료일</span>:2021-06-29</li>
          </ul>
          <a class="use">사용하기</a>
        </div>

        <div class="gamebetlist">
          <ul class="gamebetdetail w100-50">
            <li><span>쿠폰명</span>:테스트쿠폰</li>
            <li><span>쿠폰금액</span>:<span class="rd">5,000</span></li>
            <li><span>보낸회원아이디</span>:eric123</li>
            <li><span>처리상태</span>:<span class="rd">사용가능</span></li>
            <li><span>발급일</span>:2021-06-29</li>
            <li><span>만료일</span>:2021-06-29</li>
          </ul>
          <a class="use">사용하기</a>
        </div>

        <div class="gamebetlist">
          <ul class="gamebetdetail w100-50">
            <li><span>쿠폰명</span>:테스트쿠폰</li>
            <li><span>쿠폰금액</span>:<span class="rd">5,000</span></li>
            <li><span>보낸회원아이디</span>:eric123</li>
            <li><span>처리상태</span>:<span class="rd">사용가능</span></li>
            <li><span>발급일</span>:2021-06-29</li>
            <li><span>만료일</span>:2021-06-29</li>
          </ul>
          <a class="use">사용하기</a>
        </div>
      </div>

      <pagination-mobile />
    </template>

    <template v-if="type == 'gift'">
      <p class="pointname">선물 가능한 쿠폰<strong class="rd">7</strong>장</p>

      <div class="gamebetwrap">
        <div class="gamebetlist">
          <input type="checkbox" />
          <ul class="gamebetdetail w100-100">
            <li><span>쿠폰명</span>:테스트쿠폰</li>
            <li><span>쿠폰금액</span>:<span class="rd">5,000</span></li>
            <li><span>보낸회원아이디</span>:eric123</li>
            <li><span>발급일</span>:2021-06-29</li>
            <li><span>만료일</span>:2021-06-29</li>
          </ul>
          <a class="poss">사용<br />가능</a>
        </div>

        <div class="gamebetlist">
          <input type="checkbox" />
          <ul class="gamebetdetail w100-100">
            <li><span>쿠폰명</span>:테스트쿠폰</li>
            <li><span>쿠폰금액</span>:<span class="rd">5,000</span></li>
            <li><span>보낸회원아이디</span>:eric123</li>
            <li><span>발급일</span>:2021-06-29</li>
            <li><span>만료일</span>:2021-06-29</li>
          </ul>
          <a class="poss">사용<br />가능</a>
        </div>

        <div class="gamebetlist">
          <input type="checkbox" />
          <ul class="gamebetdetail w100-100">
            <li><span>쿠폰명</span>:테스트쿠폰</li>
            <li><span>쿠폰금액</span>:<span class="rd">5,000</span></li>
            <li><span>보낸회원아이디</span>:eric123</li>
            <li><span>발급일</span>:2021-06-29</li>
            <li><span>만료일</span>:2021-06-29</li>
          </ul>
          <a class="poss">사용<br />가능</a>
        </div>
      </div>

      <p class="pointname"><strong class="bbl">쿠폰선물하기</strong> 버튼을 클릭하여 쿠폰받을 회원을 선택하세요.</p>

      <div class="btnwrap">
        <a class="ok gamebetmore">쿠폰선물하기</a>
      </div>

      <pagination-mobile />

    </template>

    <template v-if="type == 'list' || type == 'giftlist'">
      <div class="coupontab">
        <a @click="type = 'list'" :class="{'on' : type == 'list'}" >쿠폰발급내역</a>
        <a @click="type = 'giftlist'" :class="{'on' : type == 'giftlist'}">쿠폰선물내역</a>
      </div>

      <template v-if="type == 'list'">
        <ul class="couponcount">
          <li>사용가능<span class="rd">32</span>장</li>
          <li>사용완료<span class="bbl">32</span>장</li>
          <li>기간만료<span class="gr">0</span>장</li>
          <li>사용가능<span class="gn">32</span>장</li>
        </ul>

        <div class="gamebetwrap">
          <div class="gamebetlist">
            <ul class="gamebetdetail w100-50">
              <li><span>쿠폰명</span>:테스트쿠폰</li>
              <li><span>쿠폰금액</span>:<span class="rd">5,000</span></li>
              <li><span>보낸회원아이디</span>:eric123</li>
              <li><span>발급일</span>:2021-06-29</li>
              <li><span>만료일</span>:2021-06-29</li>
            </ul>
            <a class="poss">사용<br />가능</a>
          </div>

          <div class="gamebetlist">
            <ul class="gamebetdetail w100-50">
              <li><span>쿠폰명</span>:테스트쿠폰</li>
              <li><span>쿠폰금액</span>:<span class="rd">5,000</span></li>
              <li><span>보낸회원아이디</span>:eric123</li>
              <li><span>발급일</span>:2021-06-29</li>
              <li><span>만료일</span>:2021-06-29</li>
            </ul>
            <a class="imposs">사용<br />완료</a>
          </div>

          <div class="gamebetlist">
            <ul class="gamebetdetail w100-50">
              <li><span>쿠폰명</span>:테스트쿠폰</li>
              <li><span>쿠폰금액</span>:<span class="rd">5,000</span></li>
              <li><span>보낸회원아이디</span>:eric123</li>
              <li><span>발급일</span>:2021-06-29</li>
              <li><span>만료일</span>:2021-06-29</li>
            </ul>
            <a class="perdone">기간<br />만료</a>
          </div>

          <div class="gamebetlist">
            <ul class="gamebetdetail w100-50">
              <li><span>쿠폰명</span>:테스트쿠폰</li>
              <li><span>쿠폰금액</span>:<span class="rd">5,000</span></li>
              <li><span>보낸회원아이디</span>:eric123</li>
              <li><span>발급일</span>:2021-06-29</li>
              <li><span>만료일</span>:2021-06-29</li>
            </ul>
            <a class="collposs">회수<br />쿠폰</a>
          </div>

        </div>

        <pagination-mobile />
      </template>

      <template v-if="type == 'giftlist'">
        <ul class="couponcount">
          <li>사용가능<span class="rd">32</span>장</li>
          <li>사용완료<span class="bbl">32</span>장</li>
          <li>기간만료<span class="gr">0</span>장</li>
          <li>사용가능<span class="gn">32</span>장</li>
        </ul>

        <div class="gamebetwrap">
          <div class="gamebetlist">
            <ul class="gamebetdetail w100-50">
              <li><span>쿠폰명</span>:테스트쿠폰</li>
              <li><span>쿠폰금액</span>:<span class="rd">5,000</span></li>
              <li><span>보낸회원아이디</span>:eric123</li>
              <li><span>보낸일시</span>:2021-06-29 15:45</li>
              <li><span>발급일</span>:2021-06-29</li>
              <li><span>만료일</span>:2021-06-29</li>
            </ul>
            <a class="giftposs">선물<br />완료</a>
          </div>

          <div class="gamebetlist">
            <ul class="gamebetdetail w100-50">
              <li><span>쿠폰명</span>:테스트쿠폰</li>
              <li><span>쿠폰금액</span>:<span class="rd">5,000</span></li>
              <li><span>보낸회원아이디</span>:eric123</li>
              <li><span>보낸일시</span>:2021-06-29 15:45</li>
              <li><span>발급일</span>:2021-06-29</li>
              <li><span>만료일</span>:2021-06-29</li>
            </ul>
            <a class="giftposs">선물<br />완료</a>
          </div>

          <div class="gamebetlist">
            <ul class="gamebetdetail w100-50">
              <li><span>쿠폰명</span>:테스트쿠폰</li>
              <li><span>쿠폰금액</span>:<span class="rd">5,000</span></li>
              <li><span>보낸회원아이디</span>:eric123</li>
              <li><span>보낸일시</span>:2021-06-29 15:45</li>
              <li><span>발급일</span>:2021-06-29</li>
              <li><span>만료일</span>:2021-06-29</li>
            </ul>
            <a class="giftposs">선물<br />완료</a>
          </div>

        </div>

        <pagination-mobile />
      </template>

    </template>

  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'

export default {
  name: 'Coupon',
  components: {
    NaviBar,
    PaginationMobile
  },
  data () {
    return {
      type: 'coupon'
    }
  }
}
</script>

<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
